<template>
  <div class="search">
    <van-nav-bar left-arrow title="查询" @click-left="onClickLeft">
    </van-nav-bar>
    <van-search
      background="#ffffff"
      clearable
      v-model="filter.keyLike"
      placeholder="可通过姓名、手机号、车牌号、业务编号进行查询"
      @search="onSearch"
    />

    <div class="history" v-if="!searched">
      <div class="head">
        <div class="l">查询历史</div>
        <div class="r" v-if="searchHistory.length" @click="clearHistory">
          清除历史记录
        </div>
      </div>
      <div class="items" v-if="searchHistory.length">
        <div
          class="item"
          v-for="(item, index) in searchHistory"
          :key="index"
          @click="onSearch(item)"
        >
          <span>{{ item }}</span>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无搜索记录" />
      </div>
    </div>

    <van-pull-refresh v-if="searched" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list-box"
      >
        <CaseItem
          v-for="(item, index) in list"
          :item="item"
          :key="index"
          class="item"
        ></CaseItem>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import * as _ from "lodash";
import Vue from "vue";
import { Search, Empty, Tabbar, TabbarItem, Toast } from "vant";

Vue.use(Empty);
Vue.use(Search);
Vue.use(Tabbar);
Vue.use(TabbarItem);

import Api from "@/api/caseHandle";

const HISTORY_KEY = "search-history";

export default {
  data() {
    return {
      filter: {
        keyLike: "",
      },
      searchHistory: [],

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNo: 1,
      pageSize: 10,
      searched: false,
    };
  },
  methods: {
    clearHistory() {
      this.searchHistory = [];
      this.writeHistory();
    },
    readHistory() {
      try {
        this.searchHistory =
          JSON.parse(localStorage.getItem(HISTORY_KEY)) || [];
      } catch (error) {
        this.searchHistory = [];
      }
    },
    writeHistory() {
      localStorage.setItem(HISTORY_KEY, JSON.stringify(this.searchHistory));
    },

    // 搜索
    async onSearch(keyLike) {
      const { searchHistory, filter } = this;
      filter.keyLike = keyLike;
      if (searchHistory.indexOf(keyLike) < 0 && _.trim(keyLike) != "") {
        searchHistory.splice(0, 0, keyLike);
        this.writeHistory();
      }

      this.searched = true;
      this.pageNo = 1;
      this.list = [];
      this.onLoad();
    },

    onSerachClick() {
      this.$router.push({
        name: "search",
      });
    },
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { pageNo, pageSize, filter } = this;
        // 去除空项
        for (const key in filter) {
          if (filter[key] === "" || filter[key] === null) {
            delete filter[key];
          }
        }
        var res = await Api.findPageISO({
          pageNo,
          pageSize,
          ...filter,
        });
        const { data } = res;
        const { list } = data;
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = !data.isNextPage;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },
  },
  mounted() {
    this.readHistory();
  },
};
</script>
<style lang="less" scoped>
.search {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .van-search {
    /deep/ input {
      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .history {
    padding: 10px 0px;
    .head {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;

      .r {
        color: #3c86ff;
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0px 0px 0px;

      .item {
        padding: 5px 10px;
        span {
          display: inline-block;
          padding: 0.8em 1em;
          background: #fff;
        }
      }
    }
  }
}
</style>